(function ($) {

    $('.mobile-icon-menu .icon-menu').on('click', function(e){
        if($(this).hasClass('active')){
            $(this).removeClass('active');
        }else{
            $(this).addClass('active');
        }
        if($(this).parents('header').find('.container-mobile-menu').hasClass('open-menu')){
            $(this).parents('header').find('.container-mobile-menu').removeClass('open-menu');
        }else{
            $(this).parents('header').find('.container-mobile-menu').addClass('open-menu');
        }

        if($(this).parents('body').hasClass('open-menu')){
            $(this).parents('body').removeClass('open-menu');
        }else{
            $(this).parents('body').addClass('open-menu');
        }
    });
    $(document).mouseup(function (e){ // событие клика по веб-документу
        var menu = $(".menu-left"),
            btnOpen = $('header .mobile-icon-menu .icon-menu');// тут указываем ID элемента
        if (!menu.is(e.target) && !btnOpen.is(e.target) // если клик был не по нашему блоку
            && menu.has(e.target).length === 0 && btnOpen.has(e.target).length === 0) { // и не по его дочерним элементам\
            menu.parents('.container-mobile-menu.open-menu').removeClass('open-menu'); // скрываем его
            menu.parents('body').removeClass('open-menu'); // скрываем его
            $('.mobile-icon-menu .icon-menu').removeClass('active');
        }
    });
    $('.container-mobile-menu .btn-close').on('click', function(){
        $('.mobile-icon-menu .icon-menu').click();
    });


    $(window).resize(function(){
        if($(window).width() < 992){
            var vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the documen
            $('header .container-mobile-menu, header .container-mobile-menu .menu-bg').css('--vh',vh+'px');

        }
    });
    if($(window).width() < 992){
        var vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the documen
        $('.header .container-mobile-menu, header .container-mobile-menu .menu-bg').css('--vh',vh+'px');

    }

    $.fancybox.defaults.modal = true;
    $('.modal-link').fancybox();
    $('.btn-close').on('click', function(){
        $('#modal-call input[name="tarif-name"]').val("");
        $('#modal-call input[name="tarif-option"]').val("");
        $.fancybox.close();
    });


    $('.modal-link-tarif').fancybox();
    $('.modal-link-tarif').on('click', function(e){
        var nameTarif = $(this).parents('.tarif').data('name-tarif'),
            nameOption = $(this).parents('.tarif').find('.option.active .text-option .value').text(),
            extraImport = false,
            extraAuto = false;
        if($(this).parents('.tarif').find('.import').hasClass('active')){
            extraImport = true;
            console.log("a1");
        }
        if($(this).parents('.tarif').find('.auto').hasClass('active')){
            extraAuto = true;
            console.log('a2');
        }
        if(extraImport == true){
            $('#modal-call input[name="tarif-extra-import"]').val("Y");
        }else{
            $('#modal-call input[name="tarif-extra-import"]').val("");
        }
        if(extraAuto == true){
            $('#modal-call input[name="tarif-extra-auto"]').val("Y");
        }else{
            $('#modal-call input[name="tarif-extra-auto"]').val("");
        }


        $('#modal-call input[name="tarif-name"]').val(nameTarif);
        $('#modal-call input[name="tarif-option"]').val(nameOption);

    });


    $('#modal-call').on('focusin',function(){
        $(this).parent().removeClass('error');
    });


    $('#modal-form-call').on('submit',function(e){
        e.preventDefault();
        var i=0;
        $(this).find('.required').each(function(){
            if(!$(this).find('input').val()){
                $(this).addClass('error');
                i++;
            }
        });
        if(i>0){
            return;
        }

        var form = new FormData(this);
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: form,
            processData: false,
            contentType: false,
            success: function() {
                $.fancybox.close();
                $.fancybox.open($('#modal-success'));
                console.log('успех');
            } ,
            error:function(){
                $.fancybox.close();
                $.fancybox.open($('#modal-error'));
                console.log('ошибка');
            }
        });

        $('#modal-success button, #modal-error button').on('click',function(){
            $('#modal-call input[name="tarif-name"]').val("");
            $('#modal-call input[name="tarif-option"]').val("");
            $('#modal-call input[name="tarif-extra-import"]').val("");
            $('#modal-call input[name="tarif-extra-auto"]').val("");
            $.fancybox.close();
        });

    });
    $("input[name='user-phone']").mask("+7(999) 999-9999");


    $('.btn-dropdown').on('click', function(){
        $(this).toggleClass('active');
    });
    $(document).mouseup(function (e){ // событие клика по веб-документу
        var menu = $(".dropdown-container");
        if (!menu.is(e.target) // если клик был не по нашему блоку
            && menu.has(e.target).length === 0) { // и не по его дочерним элементам\
            menu.parents('.btn-dropdown.active').removeClass('active'); // скрываем его
        }
    });

    $('.option').on('click', function(e){
        var _parent = $(this).parents('.options-block');

        _parent.find('.option.active').removeClass('active');

        $(this).addClass('active');

    });

    $(".extra-option").on('click', function(e){
        var _tarifOptions = $(this).parents('.body-tarif').find('.options-block');
        if(!$(this).hasClass('active')){
            $(this).addClass('active');
            _tarifOptions.find('.option').each(function(){
                var priceOption = $(this).data('option-price'),
                    currentPriceOption = $(this).data('current-option-price'),
                    priceExtra = Math.round(priceOption/6),
                    newPrice = String(currentPriceOption + priceExtra),
                    formatPrice = newPrice.slice(0, -3) + " " + newPrice.slice(-3);
                    $(this).data('current-option-price', currentPriceOption + priceExtra);
                    $(this).find('.price-value').text(formatPrice);
            });

        }else{
            $(this).removeClass('active');
            _tarifOptions.find('.option').each(function(){
                var priceOption = $(this).data('option-price'),
                    currentPriceOption = $(this).data('current-option-price'),
                    priceExtra = Math.round(priceOption/6),
                    newPrice = String(currentPriceOption - priceExtra),
                    formatPrice = newPrice.slice(0, -3) + " " + newPrice.slice(-3);
                $(this).data('current-option-price', currentPriceOption - priceExtra);
                $(this).find('.price-value').text(formatPrice);
            });
        }
    });

    function slideChange(slideId){
        $('.interface-slider [data-slide-id].active').each(function(){
            $(this).removeClass('active');
        });
        $('.interface-slider [data-slide-id="'+slideId+'"]').each(function(){
            $(this).addClass('active');
        });
        HeightChange(slideId);
    }
    function HeightChange(slideId){
        var heightCurrentText = +$('.slide-text[data-slide-id="'+slideId+'"]').height();
        $('.slide-text[data-slide-id="'+slideId+'"]').parents('.container-slider-texts').css('height', heightCurrentText+"px");

        var heightCurrentImg = +$('.slide[data-slide-id="'+slideId+'"] img').height();
        $('.slide[data-slide-id="'+slideId+'"]').parents('.slides').css('height', heightCurrentImg+"px");
        console.log(heightCurrentImg );
    }

    $('.interface-slider .btn-next').on('click', function(){
        var _slides = $('.interface-slider').find('.slides'),
            countSlides = _slides.find('.slide').length,
            currentActiveSlideId = +_slides.find('.slide.active').data('slide-id');
        if(currentActiveSlideId<countSlides){
            slideChange(++currentActiveSlideId);
        }else{
            slideChange(1);
        }
    });
    $('.interface-slider .btn-prev').on('click', function(){
        var _slides = $('.interface-slider').find('.slides'),
            countSlides = _slides.find('.slide').length,
            currentActiveSlideId = +_slides.find('.slide.active').data('slide-id');
        if(currentActiveSlideId==1){
            slideChange(countSlides);
        }else{
            slideChange(--currentActiveSlideId);
        }
    });
    $('.interface-slider .dot').on('click', function(){
        var currentSlideId = +$(this).data('slide-id');
        slideChange(currentSlideId);

    });
    $(document).ready(function(){
        if($('.interface-slider').length){
            HeightChange(1);
            console.log('да1');
            $('.interface-slider .btn-prev').click();
            $('.interface-slider .btn-next').click();
        }
    });
    $(document).on('load', function(){
        if($('.interface-slider').length){
            HeightChange(1);
            console.log('да2');
            $('.interface-slider .btn-prev').click();
            $('.interface-slider .btn-next').click();
        }
    });
    $(window).resize(function() {
        var currentSlideId = +$('.interface-slider .slide[data-slide-id].active').data('slide-id');
        HeightChange(currentSlideId);
    });
    if($('.interface-slider').length){
        HeightChange(1);
        console.log('да3');
        $('.interface-slider .btn-prev').click();
        $('.interface-slider .btn-next').click();
    }
    window.onload = function() {
        if($('.interface-slider').length){
            HeightChange(1);
            console.log('да1');
            $('.interface-slider .btn-prev').click();
            $('.interface-slider .btn-next').click();
        }
    }
    }(jQuery));